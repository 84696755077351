.sf-menu li, .sf-menu ul li, .sf-menu ul ul li, .sf-menu li:hover, .sf-menu li.sfHover {
    background: transparent;
}

.sf-menu a {
    color: #38464A;
    padding: .75em 1em;
    font-family: 'Giorgio Sans Web';
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-left: none;
    border-top: none;
    border-top: none;
    text-decoration: none;
    zoom: 1;
    font-size: 24px;
    font-weight: bold;
    border-bottom: none !important;
}