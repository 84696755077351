figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

a.navbar-brand img {
    max-width: 55px;
}

.nav-link img {
    max-width: 20px;
}