@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/roboto-v20-latin-100.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Thin"), local("Roboto-Thin"),
    url("../fonts/roboto-v20-latin-100.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-100.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-100.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* roboto-300 - latin */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v20-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"),
    url("../fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-300.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* roboto-regular - latin */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v20-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"),
    url("../fonts/roboto-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/roboto-v20-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-700 - latin */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v20-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("../fonts/roboto-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-700.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

html {
  scroll-behavior: smooth;
}

html.fh5co-overflow,
body.fh5co-overflow {
  overflow-x: auto;
}

html.fh5co-overflow #fh5co-header-section,
html.fh5co-overflow #fh5co-main,
html.fh5co-overflow #fh5co-hero,
html.fh5co-overflow #fh5co-mobile-menu,
html.fh5co-overflow #fh5co-footer,
body.fh5co-overflow #fh5co-header-section,
body.fh5co-overflow #fh5co-main,
body.fh5co-overflow #fh5co-hero,
body.fh5co-overflow #fh5co-mobile-menu,
body.fh5co-overflow #fh5co-footer {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

body {
  position: relative;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #000;
}

/* @media screen and (max-width: 768px) {
    body {
        font-size: 16px;
        line-height: 1.5;
    }
} */

::-webkit-selection {
  color: #ffffff;
  background: #1896d4;
}

::-moz-selection {
  color: #ffffff;
  background: #1896d4;
}

::selection {
  color: #ffffff;
  background: #1896d4;
}

a {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #90d7ea;
  border-bottom: 1px solid #ccc;
}

a:hover,
a:focus,
a:active {
  outline: none;
  color: #2e2e2e;
  text-decoration: underline;
  border-bottom: 1px solid #90d7ea;
}

a,
a:hover,
a:active {
  color: #1896d4;
  /* text-decoration: underline; */
  border-bottom: 0px solid;
}

/* p a {
    text-decoration: underline;
} */

input {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* Heading */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Giorgio Sans Web";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  margin: 0 0 20px 0;
  padding: 0;
  color: #000000;
}

h1,
.h1 {
  font-size: 45px;
  line-height: 52px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h2,
.h2 {
  font-size: 40px;
  letter-spacing: 1px;
  text-transform: none;
}

h3,
.h3 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
}

h4,
.h4 {
  font-size: 16px;
  line-height: 28px;
}

h5,
.h5 {
  font-size: 14px;
  line-height: 24px;
}

h6,
.h6 {
  font-size: 12px;
  line-height: 24px;
}

ul,
ol {
  padding-left: 15px;
  line-height: 26px;
}

ul ul,
ul ol,
ol ul,
ol ol {
  padding-left: 25px;
}

ul,
ol,
p {
  margin: 0 0 20px 0;
}

.fh5co-serif {
  font-family: "Crimson Text", serif;
}

.fh5co-sans-serif {
  font-family: "Lato", arial, sans-serif;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (max-width: 480px) {
  .col-xxs-12 {
    display: block;
    clear: both;
    width: 100%;
    float: left;
  }
}

/* Header */

#fh5co-header-section {
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 103;
  background-color: rgba(255, 255, 255, 0.7);
}

#fh5co-menu-logo {
  clear: both;
}

#fh5co-quick-contacts {
  float: right;
  width: 100%;
  text-align: right;
  margin-top: 2em;
}

#fh5co-quick-contacts .sep {
  padding: 0 0.5em;
  color: rgba(255, 255, 255, 0.5);
}

#fh5co-quick-contacts a {
  font-size: 14px;
  padding: 20px 10px;
  color: white;
}

#fh5co-quick-contacts a > i {
  margin-right: 10px;
  margin-top: 5px;
  color: white;
}

#fh5co-quick-contacts a:hover {
  color: white;
}

#fh5co-logo {
  font-size: 20px;
  margin: 0.45em 0 0 0;
  padding: 0;
  font-weight: 700;
  display: inline-block;
  max-width: 89px;
}

#fh5co-logo a {
  border-bottom: none !important;
  color: #ffffff;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#fh5co-logo a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  #fh5co-logo {
    text-align: center;
    margin: 0px 0 0 0;
    float: none !important;
  }
}

/* Superfish Override Menu */

.sf-menu {
  margin: 0 !important;
}

.sf-menu {
  float: right;
}

.sf-menu ul {
  box-shadow: none;
  border: transparent;
  min-width: 8em;
  *width: 8em;
}

.sf-menu a {
  color: #38464a;
  padding: 0.75em 1em;
  font-family: "Giorgio Sans Web";
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-left: none;
  border-top: none;
  border-top: none;
  text-decoration: none;
  zoom: 1;
  font-size: 17px;
  font-weight: bold;
  border-bottom: none !important;
}

.sf-menu li,
.sf-menu ul li,
.sf-menu ul ul li,
.sf-menu li:hover,
.sf-menu li.sfHover {
  background: transparent;
}

.sf-menu ul li a,
.sf-menu ul ul li a {
  text-transform: none;
  padding: 0.75em 1em;
  letter-spacing: 1px;
}

.sf-menu li:hover a,
.sf-menu li.sfHover a,
.sf-menu ul li:hover a,
.sf-menu ul li.sfHover a,
.sf-menu li.active a {
  color: #ffffff;
  /*text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.08);*/
}

.sf-menu ul li:hover,
.sf-menu ul li.sfHover {
  background: transparent;
}

.sf-menu ul li {
  background: transparent;
}

.sf-arrows .sf-with-ul {
  padding-right: 2.5em;
  *padding-right: 1em;
}

.sf-arrows .sf-with-ul:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -3px;
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #333333;
}

.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
  border-top-color: #ccc;
}

.sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #ccc;
}

.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
  border-left-color: #ccc;
}

.fh5co-special a {
  padding: 0px !important;
  margin-top: 20px;
  background: #f86942;
  padding-left: 20px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-right: 20px !important;
  color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

#fh5co-menu-wrap {
  float: right;
  position: relative;
  margin-right: -20px;
}

#fh5co-menu-wrap .sf-menu a {
  padding: 1em 1.5em 1em;
}

#fh5co-primary-menu > li > ul li.active > a {
  color: #90d7ea !important;
}

#fh5co-primary-menu > li > .sf-with-ul:after {
  border: none !important;
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  float: right;
  margin-right: 5px;
  top: 50%;
  margin-top: -7px;
  content: "\e64b";
  color: #38464a;
}

#fh5co-primary-menu > li > ul li > .sf-with-ul:after {
  border: none !important;
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  float: right;
  margin-right: 10px;
  top: 13px;
  font-size: 12px;
  content: "\e649";
  color: rgba(255, 255, 255, 0.5);
}

#fh5co-primary-menu .fh5co-sub-menu {
  padding: 7px 0 3px;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

#fh5co-primary-menu .fh5co-sub-menu:before {
  position: absolute;
  top: -9px;
  right: 20px;
  width: 0;
  height: 0;
  content: "";
}

#fh5co-primary-menu .fh5co-sub-menu:after {
  position: absolute;
  top: -8px;
  right: 21px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #90d7ea;
  border-bottom: 8px solid rgba(0, 0, 0, 0.8);
  border-left: 8px solid transparent;
  content: "";
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:before {
  top: 6px;
  right: 100%;
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:after {
  top: 7px;
  right: 100%;
  border: none !important;
}

.site-header.has-image #primary-menu .sub-menu {
  border-color: #ebebeb;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.19);
}

.site-header.has-image #primary-menu .sub-menu:before {
  display: none;
}

#fh5co-primary-menu .fh5co-sub-menu a {
  letter-spacing: 0;
  padding: 0 15px;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff !important;
  text-transform: none;
  background: none;
}

#fh5co-primary-menu .fh5co-sub-menu a:hover {
  color: #90d7ea !important;
}

.fh5co-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
}

.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background: #2e2e2e;
}

.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}

.fh5co-nav-toggle i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 3px;
  color: #252525;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #252525;
  transition: all 0.2s ease-out;
}

.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
  content: "";
  width: 25px;
  height: 3px;
  background: #252525;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
}

.fh5co-nav-toggle.fh5co-nav-white > i {
  color: #ffffff;
  background: #ffffff;
}

.fh5co-nav-toggle.fh5co-nav-white > i::before,
.fh5co-nav-toggle.fh5co-nav-white > i::after {
  background: #ffffff;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fh5co-nav-toggle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 21;
  padding: 6px 0 0 0;
  margin: 0 auto;
  display: none;
  background: #1896d4;
  height: 44px;
  width: 44px;
  border-bottom: none !important;
}

@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    display: block;
  }
}

/* Mobile Menu */

#fh5co-mobile-menu {
  -moz-transform: translateX(-275px);
  -webkit-transform: translateX(-275px);
  -ms-transform: translateX(-275px);
  transform: translateX(-275px);
  display: block;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 275px;
  z-index: 10002;
  background: #181920;
  padding: 0.75em 1.25em;
}

#fh5co-mobile-menu #fh5co-mobile-menu-ul {
  padding: 0;
  margin: 0;
}

#fh5co-mobile-menu #fh5co-mobile-menu-ul li {
  list-style: none;
}

#fh5co-mobile-menu #fh5co-mobile-menu-ul li.active > a {
  color: #ffffff;
}

#fh5co-mobile-menu #fh5co-mobile-menu-ul a {
  border-bottom: none !important;
  padding: 7px 0;
  display: block;
  color: #ccc;
  color: rgba(255, 255, 255, 0.5);
}

#fh5co-mobile-menu #fh5co-mobile-menu-ul a:hover {
  color: #ffffff;
  color: white;
}

#fh5co-mobile-menu #fh5co-mobile-menu-ul .fh5co-sub-ddown {
  position: relative;
}

#fh5co-mobile-menu #fh5co-mobile-menu-ul .fh5co-sub-ddown::after {
  font-family: "themify";
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 0;
  margin-top: 2px;
  content: "\e64b";
}

#fh5co-mobile-menu #fh5co-mobile-menu-ul .fh5co-sub-menu {
  display: none;
  padding-left: 20px;
}

#fh5co-mobile-menu #fh5co-mobile-menu-ul .fh5co-sub-menu li {
  list-style: none;
}

#fh5co-logo-mobile-wrap {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: block;
  height: 44px;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 10001;
  background: #2b303b;
  color: #ccc;
  -webkit-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  -moz-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  -ms-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  -o-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
}

#fh5co-logo-mobile-wrap h1 {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

#fh5co-logo-mobile-wrap h1 a {
  border-bottom: none !important;
  color: #ccc;
  letter-spacing: 3px;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  #fh5co-content {
    margin-bottom: 4em;
  }
}

#fh5co-sidebar {
  font-size: 14px;
}

#fh5co-sidebar .sidebar-box {
  margin-bottom: 2em;
  float: left;
  width: 100%;
}

#fh5co-sidebar .sidebar-heading {
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #2e2e2e;
  position: relative;
  padding-top: 7px;
}

#fh5co-sidebar .sidebar-heading .border {
  width: 30px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #ebebeb;
}

#fh5co-sidebar .sidebar-links {
  padding: 0;
  margin: 0 0 30px 0;
}

#fh5co-sidebar .sidebar-links li {
  padding: 0;
  margin: 0;
  list-style: none;
}

#fh5co-hero {
  background-color: #90d7ea;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
  margin-bottom: 10em;
  /* float: left; */
  width: 100%;
  height: 300px;
  display: table;
  position: relative;
  z-index: 20;
  color: #ffffff;
}

#fh5co-hero:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -40px;
  overflow: visible;
  width: 100%;
  height: 200px;
  background: #90d7ea;
  z-index: -1;
  -webkit-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
  -o-transform: skewY(-3deg);
  transform: skewY(-3deg);
  background-color: #90d7ea;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/hero6.jpg);
}

@media screen and (min-width: 585px) {
  #fh5co-hero {
    background-position: center center;
  }
}

@media screen and (min-width: 600px) {
  #fh5co-hero:after {
    height: 400px;
  }
}

#fh5co-hero .fh5co-arrow {
  position: absolute;
  bottom: -70px;
  left: 50%;
  margin-left: -30px;
  display: table;
  color: #ffffff;
  font-size: 24px;
  z-index: 99;
  text-decoration: none;
  width: 60px;
  height: 60px;
  background: #f86942;
  border-bottom: none !important;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  -moz-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  -ms-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  -o-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
}

#fh5co-hero .fh5co-arrow i {
  display: table-cell;
  vertical-align: middle;
}

#fh5co-hero .fh5co-arrow:active,
#fh5co-hero .fh5co-arrow:focus,
#fh5co-hero .fh5co-arrow:hover {
  outline: none;
}

#fh5co-hero .fh5co-hero-wrap {
  padding-top: 15em;
  display: table;
  height: 600px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #fh5co-hero .fh5co-hero-wrap {
    padding-top: 4em;
  }
}

#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro {
  vertical-align: middle;
  text-align: center;
  color: #ffffff;
}

#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro a {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro a:hover {
  color: white;
  border-bottom: 1px solid white;
}

#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro > h1 {
  font-size: 40px;
  font-weight: normal;
  color: #ffffff;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 768px) {
  #fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro > h1 {
    font-size: 30px;
  }
}

#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro > h2 {
  letter-spacing: 4px;
  line-height: 1.5;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 20px;
  position: relative;
  color: #ffffff;
  /*text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.08);*/
}

@media screen and (max-width: 768px) {
  #fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro > h2 {
    letter-spacing: 3px;
  }
}

#fh5co-hero .fh5co-hero-wrap .btn {
  color: #ffffff;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.08);
}

#fh5co-hero .fh5co-hero-wrap .btn:hover,
#fh5co-hero .fh5co-hero-wrap .btn:active,
#fh5co-hero .fh5co-hero-wrap .btn:focus {
  background: #90d7ea;
  border-color: #90d7ea;
}

body.inner-page #fh5co-hero {
  height: 400px;
}

body.inner-page #fh5co-hero:after {
  height: 750px;
}

body.inner-page #fh5co-hero .fh5co-hero-wrap {
  padding-top: 15em;
}

@media screen and (max-width: 768px) {
  body.inner-page #fh5co-hero .fh5co-hero-wrap {
    padding-top: 4em;
  }
}

@media screen and (max-width: 768px) {
  #fh5co-hero,
  .fh5co-hero-wrap {
    position: relative;
    padding: 4em 0 3em 0;
    height: inherit !important;
  }
}

#fh5co-main {
  padding-top: 50px;
  position: relative;
  float: left;
  width: 100%;
  clear: both;
  margin-bottom: 10em;
}

@media screen and (max-width: 768px) {
  #fh5co-main {
    margin-top: 0px;
    padding-top: 20px;
    margin-bottom: 7em;
  }
}

@media screen and (max-width: 768px) {
  body.inner-page #fh5co-main {
    margin-top: 0px;
    padding-top: 20px;
  }
}

#fh5co-hero,
#fh5co-main,
#fh5co-logo-mobile-wrap {
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

body.fh5co-mobile-menu-visible #fh5co-hero,
body.fh5co-mobile-menu-visible #fh5co-main,
body.fh5co-mobile-menu-visible #fh5co-logo-mobile-wrap {
  -moz-transform: translateX(275px);
  -webkit-transform: translateX(275px);
  -ms-transform: translateX(275px);
  transform: translateX(275px);
}

body.fh5co-mobile-menu-visible #fh5co-mobile-menu {
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#fh5co-works .fh5co-work-item figure {
  margin-bottom: 20px !important;
  float: left;
  width: 100%;
}

#fh5co-works .fh5co-work-item .heading {
  font-size: 17px;
  margin-bottom: 40px;
}

#fh5co-works .fh5co-work-item .fh5co-category {
  color: #ccc;
  font-size: 13px;
  margin-bottom: 0;
}

#fh5co-call-to-action {
  clear: both;
  display: block;
  padding: 3em 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  letter-spacing: 7px;
  text-transform: uppercase;
  background: transparent;
  font-size: 16px;
  color: #000000;
}

#fh5co-call-to-action:hover,
#fh5co-call-to-action:focus,
#fh5co-call-to-action:active {
  color: #ffffff;
  background: #f86942;
  border-top: 1px solid #f86942;
  border-bottom: 1px solid #f86942;
}

#fh5co-footer {
  clear: both;
  position: relative;
  padding: 4em 0 1em 0;
  background: #90d7ea;
  background: #f3f8f9;
  float: left;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
}

#fh5co-footer::before {
  content: "";
  position: absolute;
  left: 0;
  top: -4em;
  overflow: hidden;
  width: 100%;
  height: 350px;
  background: #90d7ea;
  background: #f3f8f9;
  z-index: -1;
  -webkit-transform: skewY(3deg);
  -moz-transform: skewY(3deg);
  -ms-transform: skewY(3deg);
  -o-transform: skewY(3deg);
  transform: skewY(3deg);
}

#fh5co-footer .fh5co-arrow {
  position: absolute;
  top: -84px;
  left: 50%;
  margin-left: -30px;
  display: table;
  color: #ffffff !important;
  font-size: 24px;
  z-index: 99;
  text-decoration: none;
  width: 60px;
  height: 60px;
  background: #f86942;
  border-bottom: none !important;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  -moz-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  -ms-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  -o-box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
  box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
}

#fh5co-footer .fh5co-arrow i {
  display: table-cell;
  vertical-align: middle;
}

#fh5co-footer .fh5co-arrow:active,
#fh5co-footer .fh5co-arrow:focus,
#fh5co-footer .fh5co-arrow:hover {
  outline: none;
  color: #ffffff !important;
}

#fh5co-footer .fh5co-copyright {
  margin-top: 5em;
}

#fh5co-footer .fh5co-footer-heading {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #ccc;
  margin-bottom: 10px;
}

#fh5co-footer .fh5co-footer-links {
  padding: 0;
  margin: 0 0 30px 0;
}

#fh5co-footer .fh5co-footer-links li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.quote {
  width: 80%;
  margin: 0 auto;
  font-size: 28px;
  font-weight: 300;
  line-height: 38px;
}

.quote cite {
  margin-top: 20px;
  display: block;
  font-size: 20px;
  font-style: normal;
}

@media screen and (max-width: 768px) {
  .quote {
    font-size: 20px;
    width: 100%;
  }
}

/* Helper Classes */

/* Spacer */

.fh5co-spacer {
  clear: both;
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
}

.fh5co-spacer-xlg {
  height: 150px;
}

@media screen and (max-width: 768px) {
  .fh5co-spacer-xlg {
    height: 70px;
  }
}

.fh5co-spacer-lg {
  height: 100px;
}

@media screen and (max-width: 768px) {
  .fh5co-spacer-lg {
    height: 50px;
  }
}

.fh5co-spacer-md {
  height: 80px;
}

@media screen and (max-width: 768px) {
  .fh5co-spacer-md {
    height: 30px;
  }
}

.fh5co-spacer-sm {
  height: 50px;
}

@media screen and (max-width: 768px) {
  .fh5co-spacer-sm {
    height: 20px;
  }
}

.fh5co-spacer-xs {
  height: 30px;
}

@media screen and (max-width: 768px) {
  .fh5co-spacer-xs {
    height: 20px;
  }
}

.fh5co-spacer-xxs {
  height: 20px;
}

.fh5co-letter-spacing {
  letter-spacing: 1px;
}

.fh5co-no-margin-bottom {
  margin-bottom: 0 !important;
}

.fh5co-uppercase-heading-sm {
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #ccc;
}

.form-group {
  margin-bottom: 30px;
}

.image-popup:hover {
  opacity: 0.7;
}

.fh5co-section-heading .fh5co-lead {
  text-transform: uppercase;
  letter-spacing: 5px;
  position: relative;
}

.fh5co-section-heading .fh5co-lead > .fh5co-line {
  height: 2px;
  display: block;
  width: 100px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -50px;
  background: rgba(0, 0, 0, 0.3);
}

.fh5co-section-heading .fh5co-sub {
  font-size: 18px;
}

/* 
========================================

Components 

========================================
*/

/* Buttons */

.btn {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-bottom: none !important;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  margin-bottom: 20px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
}

.btn:hover,
.btn:focus,
.btn:active {
  box-shadow: none;
  outline: none !important;
  border-color: transparent;
}

.btn.fh5co-btn-icon {
  text-transform: none !important;
  letter-spacing: normal !important;
  padding-left: 15px;
  padding-right: 15px;
}

.btn-outline {
  border: 2px solid #eaeaea !important;
  background: transparent;
  color: #2a2e37;
}

.btn-outline:hover,
.btn-outline:active,
.btn-outline:focus {
  border: 2px solid #1896d4 !important;
  background: #1896d4 !important;
  color: #ffffff;
}

.btn-primary {
  background: #1896d4;
}

.btn-success {
  background: #00e195;
}

.btn-danger {
  background: #e02745;
}

.btn-info {
  background: #0bbff2;
}

.btn-warning {
  background: #ffd042;
}

.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  border-color: transparent;
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-success:hover,
.btn-success:active,
.btn-success:focus,
.btn-info:hover,
.btn-info:active,
.btn-info:focus,
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
  background: #282e3c;
  color: #ffffff;
}
/* 
.form-control {
    box-shadow: none !important;
    border: 2px solid #ccc;
}

.form-control:hover, .form-control:focus, .form-control:active {
    outline: none;
    box-shadow: none !important;
    border: 2px solid #90d7ea;
} */

.js .to-animate,
.js .feature-box,
.js .work-box,
.js .footer-box,
.js .animate-box {
  opacity: 1;
}

/* Features*/

#fh5co-features .fh5co-feature {
  margin-bottom: 30px;
}

#fh5co-features .fh5co-feature .heading {
  font-size: 18px;
  margin: 0;
  font-weight: normal;
  color: #2e2e2e;
}

#fh5co-features .fh5co-feature-icon {
  margin: 0 auto 2em auto;
  text-align: center;
  border-radius: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

#fh5co-features .fh5co-feature-icon i {
  vertical-align: middle;
  font-size: 50px;
  color: #90d7ea;
}

/* Header */

.fh5co-header {
  text-align: center;
}

.fh5co-header .fh5co-heading {
  font-weight: bold;
  font-size: 45px;
  line-height: 57px;
}

@media screen and (max-width: 768px) {
  .fh5co-header .fh5co-heading {
    font-size: 30px !important;
    line-height: 42px !important;
  }
}

.fh5co-header .fh5co-heading-sub {
  color: #777;
  font-size: 18px;
  line-height: 30px;
}

/* Easy Rsponsive Tabs */

.fh5co-tab {
  clear: both;
  display: block;
}

.resp-tab-active {
  color: #90d7ea;
}

.resp-content-active,
.resp-accordion-active {
  display: block;
}

.fh5co-tab-menu-icon {
  font-size: 20px;
  position: relative;
  float: left;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .fh5co-tab-menu-icon {
    margin-top: 7px;
  }
}

#fh5co-feature-slider .fh5co-item-slide-text {
  margin-top: 1em;
}

#fh5co-feature-slider .fh5co-item-slide-text > h2 {
  position: relative;
  padding-bottom: 20px;
}

#fh5co-feature-slider .fh5co-item-slide-text > h2 span {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 3px;
  background: #ccc;
  background: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 992px) {
  #fh5co-feature-slider .fh5co-item-slide-text {
    margin-top: 0em;
  }
}

@media screen and (max-width: 768px) {
  #fh5co-feature-slider .fh5co-item-slide-text {
    margin-top: 0em;
  }
}

@media screen and (max-width: 480px) {
  #fh5co-feature-slider .fh5co-item-slide-text {
    margin-top: 0em;
  }
}

#fh5co-testimonial {
  padding: 10em 0;
  background: #000 url(../images/hero2.jpg) no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  #fh5co-testimonial {
    padding: 3em 0;
  }
}

#fh5co-testimonial blockquote {
  padding-left: 0;
  width: 70%;
  margin: 0 auto;
  color: #ffffff;
  border-left: none;
  font-size: 45px;
  line-height: 57px;
}

@media screen and (max-width: 768px) {
  #fh5co-testimonial blockquote {
    width: 100%;
    font-size: 35px;
    line-height: 47px;
  }
}

#fh5co-testimonial blockquote p {
  text-align: center;
  color: #ffffff;
}

#fh5co-testimonial .fh5co-testimonial-author {
  font-size: 18px;
}

#fh5co-testimonial .fh5co-uppercase-heading-sm {
  color: #ffffff;
}

/* Accordions */

.fh5co-accordion .panel-title > a {
  border-bottom: none !important;
}

.fh5co-accordion .panel-title > a:hover {
  border-bottom: none !important;
}

.fh5co-accordion .panel-heading {
  background: transparent;
  position: relative;
  cursor: pointer;
}

.fh5co-accordion .panel-heading .accordion-toggle {
  color: #90d7ea;
}

.fh5co-accordion .panel-heading .accordion-toggle:after {
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2e2e2e !important;
  position: absolute;
  content: "\e622";
  right: 15px;
  top: 16px;
}

.fh5co-accordion .panel-heading.collapsed .accordion-toggle {
  color: #2e2e2e !important;
}

.fh5co-accordion .panel-heading.collapsed .accordion-toggle:after {
  font-family: "themify";
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2e2e2e !important;
  position: absolute;
  content: "\e61a";
  right: 15px;
  top: 16px;
}

/* Progress Bars */

.progress {
  height: 15px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
}

.progress-bar {
  box-shadow: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
}

.progress-bar.progress-bar-default {
  background: #90d7ea;
}

.progress-bar.progress-bar-success {
  background: #00e195;
}

.progress-bar.progress-bar-info {
  background: #0bbff2;
}

.progress-bar.progress-bar-warning {
  background: #ffd042;
}

.progress-bar.progress-bar-danger {
  background: #e02745;
}

/* Social Icons */

.fh5co-social-icons {
  padding: 0;
}

.fh5co-social-icons li {
  list-style: none;
  display: inline;
  display: inline-block;
}

.fh5co-social-icons li a {
  height: 40px;
  width: 40px;
  border: 1px solid #ebebeb;
  display: table;
  text-align: center;
  color: #2e2e2e;
  background-color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.fh5co-social-icons li a:hover {
  background: #f86942;
  border: 1px solid #f86942 !important;
  color: #ffffff !important;
}

.fh5co-social-icons li i {
  display: table-cell;
  vertical-align: middle;
  font-size: 18px;
}

/* Pricing Tables */

@media screen and (max-width: 992px) {
  .fh5co-pricing-table-1 .fh5co-pricing-table-item {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .fh5co-pricing-table-1 .fh5co-pricing-table-item {
    margin-bottom: 20px !important;
    float: left;
    width: 100%;
  }
}

.fh5co-pricing-table-1 .fh5co-pricing-table-item .fh5co-pricing-table-item-body,
.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-heading {
  text-align: center;
  float: left;
  width: 100%;
  padding: 1em 2em;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-heading {
  background: #90d7ea;
  color: #ffffff;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-heading
  h3 {
  font-size: 70px;
  position: relative;
  display: inline-block;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-heading
  h3
  sup {
  position: absolute;
  top: 2px;
  margin-left: -7px;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-heading
  h3
  sup,
.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-heading
  h3
  span {
  font-size: 14px;
  text-transform: uppercase;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-heading
  p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item.fh5co-best-offer
  .fh5co-pricing-table-item-heading {
  background: #90d7ea;
  color: #ffffff;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-body {
  border: 2px solid #ccc;
  border-top: none;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-body
  ul {
  padding: 0;
  margin: 0;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item
  .fh5co-pricing-table-item-body
  ul
  li {
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
}

.fh5co-pricing-table-1
  .fh5co-pricing-table-item.fh5co-best-offer
  .fh5co-pricing-table-item-body {
  border: 2px solid #90d7ea;
  border-top: none;
}

/* Nav Links */

.fh5co-nav-links ul {
  padding: 0;
  margin: 0;
}

.fh5co-nav-links ul li {
  padding: 0;
  margin: 0 0 0.5em 0;
  list-style: none;
}

.fh5co-nav-links ul li.active a {
  color: #2e2e2e;
  border-bottom: 2px solid #90d7ea;
}

/* Image Alignment */

img.fh5co-align-right {
  float: right;
  margin: 0 0 0.5em 1em;
}

@media screen and (max-width: 480px) {
  img.fh5co-align-right {
    width: 100%;
    margin: 0 0 0.5em 0;
  }
}

img.fh5co-align-left {
  float: left;
  margin: 0 1em 0.5em 0;
}

@media screen and (max-width: 480px) {
  img.fh5co-align-left {
    width: 100%;
    margin: 0 0 0.5em 0;
  }
}

img.fh5co-align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a > img.fh5co-align-right {
  float: right;
  margin: 0 0 0.5em 1em;
}

@media screen and (max-width: 480px) {
  a > img.fh5co-align-right {
    width: 100%;
    margin: 0 0 0.5em 0;
  }
}

a > img.fh5co-align-left {
  float: left;
  margin: 0 1em 0.5em 0;
}

@media screen and (max-width: 480px) {
  a > img.fh5co-align-left {
    width: 100%;
    margin: 0 0 0.5em 0;
  }
}

a > img.fh5co-align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text-image {
  display: block;
  width: 100%;
  height: 25em;
  object-fit: cover;
}

.pull-up {
  margin-top: -140px;
}

.pull-down {
  margin-top: 140px;
}

/* Social Icons */

.interface {
  padding: 0;
  margin-top: -46px;
}

.interface li {
  list-style: none;
  display: inline;
  display: inline-block;
}

.interface li div {
  height: 40px;
  width: 40px;
  border: 1px solid #ebebeb;
  display: table;
  text-align: center;
  color: #2e2e2e;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  text-decoration: none;
}

.interface li div:hover {
  background: #1896d4;
  border: 1px solid #1896d4 !important;
  color: #ffffff !important;
  cursor: pointer;
  opacity: 0.5;
}

.interface li i {
  display: table-cell;
  vertical-align: middle;
  font-size: 18px;
}

html.fh5co-overflow #page-hero,
body.fh5co-overflow #page-hero {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#page-hero {
  background-color: #90d7ea;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
  margin-bottom: 2em;
  float: left;
  width: 100%;
  height: 250px;
  display: block;
  position: relative;
  z-index: 20;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  #page-hero {
    background-position: center center;
  }
}

#page-hero:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -40px;
  overflow: visible;
  width: 100%;
  height: 750px;
  background: #90d7ea;
  z-index: -1;
  -webkit-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
  -o-transform: skewY(-3deg);
  transform: skewY(-3deg);
  background-color: #90d7ea;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/hero6.jpg);
}

#page-hero .page-hero-wrap {
  padding-top: 15em;
  display: table;
  height: 600px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #page-hero .page-hero-wrap {
    padding-top: 4em;
  }
}

#page-hero .page-hero-wrap .page-hero-intro {
  vertical-align: middle;
  text-align: center;
  color: #ffffff;
}

#page-hero .page-hero-wrap .btn {
  color: #ffffff;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.08);
}

#page-hero .page-hero-wrap .btn:hover,
#page-hero .page-hero-wrap .btn:active,
#page-hero .page-hero-wrap .btn:focus {
  background: #90d7ea;
  border-color: #90d7ea;
}

.fh5co-uppercase-heading-sm {
  font-size: 2em;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #333333;
}

#thanks {
  display: none;
}

#error {
  display: none;
}

.fh5co-feature-icon a {
  text-decoration: none;
  color: inherit;
}

.fh5co-feature-icon a:hover {
  text-decoration: none;
  color: inherit;
}

.fh5co-feature-icon a {
  border-bottom: none;
}

/* 
.overlay_image {
position: relative;
top: -200px;
left: 75px;
z-index: 99;
}

.overlay_titel_text {
color: #fff;
font-size: 25px;
padding: 10px;
position: relative;
text-align: center;
text-transform: uppercase;
width: 100%;
z-index: 99;
}

.owl-carousel .owl-item img.image_slider_sdg {
width: 12.5%;
height: auto;
}

.owl-item .item {
padding-top: -37px;
margin-top: -200px;
}

.owl-item .item img {
z-index: 1;
} */

.fancybox-slide a,
.fancybox-slide a:hover,
.fancybox-slide a:active {
  color: #1896d4;
  text-decoration: none;
  border-bottom: 0px solid;
  background: #fff;
}

.sdg_background_1 {
  background: #e6273c none repeat scroll 0 0;
}

.sdg_background_2 {
  background: #dda73a none repeat scroll 0 0;
}

.sdg_background_3 {
  background: #4c9f37 none repeat scroll 0 0;
}

.sdg_background_4 {
  background: #c51c2e none repeat scroll 0 0;
}

.sdg_background_5 {
  background: #e8422e none repeat scroll 0 0;
}

.sdg_background_6 {
  background: #2ebce0 none repeat scroll 0 0;
}

.sdg_background_7 {
  background: #fcc310 none repeat scroll 0 0;
}

.sdg_background_8 {
  background: #a31b43 none repeat scroll 0 0;
}

.sdg_background_9 {
  background: #ed692f none repeat scroll 0 0;
}

.sdg_background_10 {
  background: #de1868 none repeat scroll 0 0;
}

.sdg_background_11 {
  background: #f59c2c none repeat scroll 0 0;
}

.sdg_background_12 {
  background: #bf8c2f none repeat scroll 0 0;
}

.sdg_background_13 {
  background: #3f7f45 none repeat scroll 0 0;
}

.sdg_background_14 {
  background: #1896d4 none repeat scroll 0 0;
}

.sdg_background_15 {
  background: #61b235 none repeat scroll 0 0;
}

.sdg_background_16 {
  background: #02699e none repeat scroll 0 0;
}

.sdg_background_17 {
  background: #1a496a none repeat scroll 0 0;
}

.sdg_h2 {
  width: 150px;
  height: auto;
}

#fh5co-hero {
  background-color: #fff;
  margin-bottom: 0;
}

#fh5co-hero.headerhome::after {
  /*transform: none;*/
  bottom: 26px;
}

#fh5co-hero.headerhome h2 {
  font-size: 1.25em;
  letter-spacing: 4px;
}

#fh5co-hero.headerhome h3 {
  font-size: 1.25em;
  letter-spacing: 4px;
}

#fh5co-header-section {
  position: fixed;
}

#fh5co-header-section {
  background-color: rgba(255, 255, 255, 0.9);
}

#fh5co-hero::after {
  transform: none;
  background: #fff no-repeat scroll center center / cover;
  bottom: -160px;
}

#fh5co-hero::after {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 92%, 0% 100%, 0% 0%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 92%, 0% 100%, 0% 0%);
}

#fh5co-logo img {
  max-width: 55px;
}

#fh5co-footer .fh5co-arrow,
#fh5co-hero .fh5co-arrow {
  background: #1896d4 none repeat scroll 0 0;
}

#fh5co-hero .fh5co-hero-wrap .btn:hover,
#fh5co-hero .fh5co-hero-wrap .btn:active,
#fh5co-hero .fh5co-hero-wrap .btn:focus {
  background: #1896d4;
  border-color: #1896d4;
}

#fh5co-hero .fh5co-hero-wrap {
  padding-top: 9em;
}

#fh5co-hero.fh5co-hero-default {
  margin-bottom: 10em;
}

#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro > h2.kg_notransform {
  text-transform: none;
  font-size: 24px;
  /*text-shadow: #fff 2px 2px 1px;*/
}

#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro > h2 {
  color: #000;
}

.fh5co-hero-intro > h3.kg_notransform {
  color: #000;
  /*text-shadow: #fff 2px 2px 1px;*/
}

#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro > h2 {
  padding-bottom: 10px;
  margin-bottom: 5px;
}

#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro > h2.white_shadow_black,
#fh5co-hero .fh5co-hero-wrap .fh5co-hero-intro > h3.white_shadow_black {
  color: #fff;
  /*text-shadow: #000 2px 2px 1px;*/
}

.sf-menu a {
  text-transform: none;
  font-family: "Giorgio Sans Web";
  font-weight: 700;
  font-size: 24px;
}

.sf-menu a:hover {
  color: #1896d4;
}

.sf-menu img {
  width: 20px;
  height: auto;
  padding-bottom: 2px;
}

.sf-menu img svg {
  width: 20px;
  height: auto;
  padding-bottom: 2px;
}

.menu_facebook {
  float: left;
}

.sf-menu {
  float: left;
}

.sf-menu li:hover a,
.sf-menu li.sfHover a,
.sf-menu ul li:hover a,
.sf-menu ul li.sfHover a,
.sf-menu li.active a {
  color: #1896d4;
}

.suelogo {
  width: 100%;
  max-width: 350px;
  height: auto;
}

#fh5co-logo-mobile img {
  height: 25px;
}

.kg_imprint {
  padding-top: 2.5em;
}

ul.akteure {
  margin: 2px;
  padding: 2px;
  list-style-type: none;
  border: 0px;
}

ul.akteure li {
  padding: 5px;
  list-style-type: none;
  border: 1px solid #1896d4;
  background: #1896d4;
  border-radius: 4px;
  font-family: "Giorgio Sans Web";
  font-size: 1.25em;
  letter-spacing: 5px;
  line-height: 26px;
  color: #fff;
  font-weight: 600;
}

ul.akteure li ul li {
  padding: 5px;
  list-style-type: none;
  font-family: "Neuzeit S LT W01 Book", arial, sans-serif;
  font-size: 16px;
  line-height: 1.3em;
  color: #fff;
  letter-spacing: 0px;
  font-weight: 400;
}

ul.akteure li ul li {
  display: none;
}

ul.akteure li:hover ul li {
  display: block;
}

ul.akteure li.sdg_background_1,
ul.akteure li.sdg_background_1 ul li {
  border: 1px solid #e6273c;
  background: #e6273c;
}

ul.akteure li.sdg_background_2,
ul.akteure li.sdg_background_2 ul li {
  border: 1px solid #dda73a;
  background: #dda73a;
}

ul.akteure li.sdg_background_3,
ul.akteure li.sdg_background_3 ul li {
  border: 1px solid #4c9f37;
  background: #4c9f37;
}

ul.akteure li.sdg_background_4,
ul.akteure li.sdg_background_4 ul li {
  border: 1px solid #c51c2e;
  background: #c51c2e;
}

ul.akteure li.sdg_background_5,
ul.akteure li.sdg_background_5 ul li {
  border: 1px solid #e8422e;
  background: #e8422e;
}

ul.akteure li.sdg_background_6,
ul.akteure li.sdg_background_6 ul li {
  border: 1px solid #2ebce0;
  background: #2ebce0;
}

ul.akteure li.sdg_background_7,
ul.akteure li.sdg_background_7 ul li {
  border: 1px solid #fcc310;
  background: #fcc310;
}

ul.akteure li.sdg_background_8,
ul.akteure li.sdg_background_8 ul li {
  border: 1px solid #a31b43;
  background: #a31b43;
}

ul.akteure li.sdg_background_9,
ul.akteure li.sdg_background_9 ul li {
  border: 1px solid #ed692f;
  background: #ed692f;
}

ul.akteure li.sdg_background_10,
ul.akteure li.sdg_background_10 ul li {
  border: 1px solid #de1868;
  background: #de1868;
}

ul.akteure li.sdg_background_11,
ul.akteure li.sdg_background_11 ul li {
  border: 1px solid #f59c2c;
  background: #f59c2c;
}

ul.akteure li.sdg_background_12,
ul.akteure li.sdg_background_12 ul li {
  border: 1px solid #bf8c2f;
  background: #bf8c2f;
}

ul.akteure li.sdg_background_13,
ul.akteure li.sdg_background_13 ul li {
  border: 1px solid #3f7f45;
  background: #3f7f45;
}

ul.akteure li.sdg_background_14,
ul.akteure li.sdg_background_14 ul li {
  border: 1px solid #1896d4;
  background: #1896d4;
}

ul.akteure li.sdg_background_15,
ul.akteure li.sdg_background_15 ul li {
  border: 1px solid #61b235;
  background: #61b235;
}

ul.akteure li.sdg_background_16,
ul.akteure li.sdg_background_16 ul li {
  border: 1px solid #02699e;
  background: #02699e;
}

ul.akteure li.sdg_background_17,
ul.akteure li.sdg_background_17 ul li {
  border: 1px solid #1a496a;
  background: #1a496a;
}

ul.akteure li a,
ul.akteure li a:hover {
  color: #fff;
}

.sdg_div_lightbox {
  width: 45%;
}

.sdg_lightbox_image {
  width: 100px;
  height: auto;
  padding: 5px;
}

a.hoch {
  vertical-align: 5px;
  font-size: 60%;
  text-decoration: none;
}

.sdgs-item a.ti-arrow-right,
.sdgs-item a.ti-arrow-left {
  font-size: 30px;
  color: #000;
  text-decoration: none;
}

.sdgs-item a.ti-arrow-right:hover {
  margin-right: -10px;
}

.sdgs-item a.ti-arrow-left:hover {
  margin-right: 10px;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.fh5co-uppercase-heading-sm {
  letter-spacing: 0px;
  color: #000;
}

h1.fh5co-uppercase-heading-sm {
  font-size: 48px;
  letter-spacing: 3px;
  line-height: 0.9;
}

/* h2 {font-size:26px;font-size: 35px;letter-spacing: 3px;} */

/* .onePager h2 {font-size:32px;} */

h3 + ul {
  margin-top: -15px;
}

label {
  display: inline-block;
  font-weight: 300;
  margin-bottom: 5px;
  width: auto;
  font-size: 14px;
}

label.radio {
  display: inline-block;
  font-weight: 300;
  margin-left: 18px;
  margin-bottom: 5px;
  width: 25px;
  font-size: 14px;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 0px solid #fff;
  cursor: pointer;
}

#beitreten input,
#beitreten textarea {
  border-top: none;
  border-left: none;
  border-bottom: 1px solid #000;
  border-right: none;
}

#fh5co-mobile-menu-ul img {
  max-width: 25px;
}

@media (min-width: 768px) and (max-width: 990px) {
  #fh5co-menu-wrap .sf-menu a {
    padding: 1.25em 0.75em 0.75em;
  }
}

@media (min-width: 1700px) {
  #fh5co-hero::after {
    height: 800px;
  }
}

@media (min-width: 1240px) and (max-width: 1699px) {
  #fh5co-hero::after {
    height: 650px;
  }
}

@media (min-width: 800px) and (max-width: 1239px) {
  #fh5co-hero::after {
    height: 400px;
  }
}

@media (min-width: 300px) and (max-width: 650px) {
  #fh5co-hero .fh5co-hero-wrap {
    padding-top: 1em;
    height: auto;
  }
  /* #fh5co-hero::after {
        height: 1200px;
    } */
}

@media (min-width: 651px) and (max-width: 1240px) {
  #fh5co-hero .fh5co-hero-wrap {
    height: 500px;
  }
}

@media (min-width: 300px) and (max-width: 650px) {
  .sdg_div_lightbox {
    width: 98%;
  }
  fancybox-slide > * {
    padding: 12px;
  }
}

label.error {
  color: #de1868;
  font-size: 11px;
  margin-left: 5px;
}

.row {
  position: relative;
}

.icon {
  position: absolute;
  top: -13.5em;
  right: 1.2em;
  z-index: 20;
}

.icon img {
  border: 10px solid #fff;
}

select {
  width: 100%;
  padding: 0.4em;
  border: 0;
  border: 1px solid;
  margin-bottom: 0.5em;
}

.search-box input[type="text"] {
  width: 100%;
  border: 0;
  border: 1px solid;
  padding: 0.4em;
  margin-bottom: 0.5em;
}

/* The container */

label {
  display: inline-block;
  position: relative;
  font-weight: normal;
  padding-left: 18px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 1em;
}

/* Hide the browser's default checkbox */

label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 4px;
  left: 0px;
  height: 12px;
  width: 12px;
  background-color: #fff;
  border: 1px solid;
}

/* On mouse-over, add a grey background color */

label:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

label input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

label .checkmark:after {
  left: 3px;
  top: -1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (min-width: 992px) {
  .result__list {
    height: 510px;
  }
  .result__text {
    position: relative;
  }
}

.result__list {
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: 510px;
}

.result__text span {
  font-size: 14px;
  display: inline-block;
}

.result__list span {
  text-decoration: underline;
}

.result__list ul {
  list-style: none;
  text-align: left;
  padding: 0;
  overflow: auto;
}

.result__list ul li {
  /* border-top:1px solid; */
  position: relative;
  padding: 10px 10px 10px 45px;
  /* background:transparent !important; */
  line-height: 1.4;
}

.result__list ul li:nth-child(even) {
  background: #fbfbfb;
}

.result__list ul li:nth-child(odd) {
  background: #eeeeee;
}

.result__list ul li::before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  left: 5px;
  top: 11px;
}

.ship-div-icon.SDG1,
.result ul li.SDG1::before {
  background: #de1131;
}

.ship-div-icon.SDG2,
.result ul li.SDG2::before {
  background: #e1a928;
}

.ship-div-icon.SDG3,
.result ul li.SDG3::before {
  background: #4b9b36;
}

.ship-div-icon.SDG4,
.result ul li.SDG4::before {
  background: #c41723;
}

.ship-div-icon.SDG5,
.result ul li.SDG5::before {
  background: #e6311f;
}

.ship-div-icon.SDG6,
.result ul li.SDG6::before {
  background: #37bce3;
}

.ship-div-icon.SDG7,
.result ul li.SDG7::before {
  background: #fac503;
}

.ship-div-icon.SDG8,
.result ul li.SDG8::before {
  background: #a5193c;
}

.ship-div-icon.SDG9,
.result ul li.SDG9::before {
  background: #eb621d;
}

.ship-div-icon.SDG10,
.result ul li.SDG10::before {
  background: #da075f;
}

.ship-div-icon.SDG11,
.result ul li.SDG11::before {
  background: #f59d13;
}

.ship-div-icon.SDG12,
.result ul li.SDG12::before {
  background: #c48e13;
}

.ship-div-icon.SDG13,
.result ul li.SDG13::before {
  background: #417d3e;
}

.ship-div-icon.SDG14,
.result ul li.SDG14::before {
  background: #2796d4;
}

.ship-div-icon.SDG15,
.result ul li.SDG15::before {
  background: #5eb130;
}

.ship-div-icon.SDG16,
.result ul li.SDG16::before {
  background: #11649b;
}

.ship-div-icon.SDG17,
.result ul li.SDG17::before {
  background: #1c4669;
}

/* .result ul li:nth-last-child(1) {
border-bottom:1px solid;   
} */

.result ul li h3 {
  position: relative;
  margin: 0;
  cursor: pointer;
}

.result span {
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
}

.result > ul > li > p span {
  display: inline-block;
}

.result > ul > li > p span small {
  font-size: 1rem;
}

/* .result ul li:nth-last-child(1) {
border-bottom:1px solid;   
} */

.result > ul > li > span {
  display: inline-block;
}

.result li .inhalt {
  position: relative;
  margin-top: 10px;
  padding: 15px 5px;
  width: 100%;
  display: none;
  z-index: 2;
  overflow: hidden;
}

.ship-div-icon {
  border: 1px solid #fff;
  width: 25px !important;
  height: 25px !important;
}

/* .anzahl, .tag {  
position:absolute;
bottom:0.5em;
} */

/* .tag { padding-left:10em;} */

.seite {
  position: relative;
  font-size: 1rem;
}

.seite input {
  background: none;
  border: 0;
}

#map {
  width: 100%;
  height: 550px;
  margin-bottom: 20px;
}

.verbunden {
  clear: both;
  display: block;
}

.verbunden .row {
  margin-left: -5px;
  margin-right: -5px;
}

.verbunden .row .col-sm-3 {
  padding-right: 5px;
  padding-left: 5px;
}

/* .verbunden#SDG1 {
    background: red;
    padding: 0.5em;
    display: inline-block;
    cursor: pointer;
} */

/* Additional styles 10-2019 CO */

.result ul li h3 {
  margin: 0;
  margin-bottom: -3px;
  letter-spacing: 1px;
  font-size: 24px;
}

.result ul li h3:after {
  content: "";
  position: absolute;
  top: 0px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  line-height: 1rem;
  opacity: 0.2;
  background-image: url("/assets/images/arrow.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.result ul li h3::after:hover {
  opacity: 0.6;
  -webkit-animation: rotate-90-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-90-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.suchmodul select,
.suchmodul .search-box input {
  height: 40px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 0;
}

.suchmodul .search-box input {
  position: relative;
}

.suchmodul .search-box:after {
  content: "";
  position: absolute;
  right: 2px;
  top: 0;
  width: 36px;
  height: 36px;
  margin: 2px;
  background-image: url("/assets/images/search.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.suchmodul select {
  -moz-appearance: none;
  /* -webkit-appearance: none;
appearance: none; */
  outline: none;
  cursor: pointer;
}

.leaflet-map-pane svg {
  height: 25px;
  width: 25px;
}

p.search-box__result__category {
  margin-bottom: 0;
  margin-top: 5px;
}

.col-sm-2.search-box__result__inhalt__connectedSdgs__item {
  padding-right: 5px;
  padding-left: 5px;
}

.search-box__result__inhalt__connectedSdgs__item .thumbnail {
  padding: 0;
}

.suchmodul select::-ms-expand {
  display: none;
}

.suchmodul select:focus::-ms-value {
  background-color: transparent;
}

#mitgliedWerden {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 20px;
}

#mitgliedWerden div:last-of-type {
  padding-top: 15px;
}

#mitgliedWerden button.btn {
  margin-right: 0;
  border-radius: 0;
}

small,
.small {
  font-size: 14px;
}

/* styles fuer embedded videos */

.embed-responsive iframe {
  margin-top: 1px;
}

/* Bootstrap-overrides */

@media (min-width: 1200px) {
  .container {
    width: 990px;
  }
}

@media (min-width: 768px) {
  .facebook {
    padding-right: 10px !important;
  }

  .instagram {
    padding-left: 10px !important;
  }
}

.tooltip__text {
  color: #1896d4;
}

.honeypot {
  position: absolute;
  left: -9999px;
}

#contact__form {
  margin-top: 50px;
  margin-bottom: 150px;
}

/* Slider Videos */

.item iframe {
  width: 100%;
}

.header__logo {
  max-width: 55px;
}

.info img {
  width: 125px;
}

.info__box {
  border: 2px solid black;
  padding: 20px;
  overflow: hidden;
  height: 100%;
}

.info__box .row {
  min-height: 125px;
}

section {
  width: 100%;
  padding-top: 50px;
}

@media (min-width: 768px) {
  section {
    padding-top: 100px;
  }
  .sdgscontainer {
    margin-top: 40px;
  }
}

.sdgscontainer img {
  width: 100%;
  height: auto;
  background: #000;
  transition: opacity 0.5s;
}

.sdgscontainer img:hover {
  opacity: 0.5;
  background: #000;
  transition: opacity 0.5s;
}

.sdgscontainer a img {
  color: #fff;
}

.thumbnail img {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-bottom: 40px;
  }
}

.owl-carousel__item--bgimage {
  position: relative;
  width: 100%;
  max-height: 225px;
}

.owl-carousel__item--image {
  position: relative;
  width: 100px;
  margin-top: -80px;
  margin-left: 20px;
}

.owl-carousel__item--text {
  margin-top: 20px;
  color: black;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none !important;
}

.owl-theme .owl-dots .owl-dot span {
  border-radius: 0;
  background-color: #fff;
  border: 1px solid grey;
}
