html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

@media (min-width: 768px){
    section {
        padding-top: 100px;
    }
    .sdgscontainer {
        margin-top: 40px;
    }

}

footer .foerderer p img {
    max-width: 250px;
}

.foerderer__img {
    max-width: 200px;
}