@font-face {
    font-family: 'Giorgio Sans Web';
    src: url('../fonts/GiorgioSans-Bold-Web.eot');
    src: url('../fonts/GiorgioSans-Bold-Web.eot?#iefix') format('embedded-opentype'),
         url('../fonts/GiorgioSans-Bold-Web.woff2') format('woff2'),
         url('../fonts/GiorgioSans-Bold-Web.woff') format('woff');
    font-weight:  700;
    font-style:   normal;
    font-stretch: normal;
  }
  
  .GiorgioSans-Bold-Web {
    font-family: 'Giorgio Sans Web';
    font-weight:  700;
    font-style:   normal;
    font-stretch: normal;
  }
  
  
  @font-face {
    font-family: 'Giorgio Sans Web';
    src: url('../fonts/GiorgioSans-Extralight-Web.eot');
    src: url('../fonts/GiorgioSans-Extralight-Web.eot?#iefix') format('embedded-opentype'),
         url('../fonts/GiorgioSans-Extralight-Web.woff2') format('woff2'),
         url('../fonts/GiorgioSans-Extralight-Web.woff') format('woff');
    font-weight:  200;
    font-style:   normal;
    font-stretch: normal;
  }
  
  .GiorgioSans-Extralight-Web {
    font-family: 'Giorgio Sans Web';
    font-weight:  200;
    font-style:   normal;
    font-stretch: normal;
  }
  

  

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/roboto-v20-latin-100.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Thin'), local('Roboto-Thin'), url('../fonts/roboto-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v20-latin-100.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v20-latin-100.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-300 - latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-v20-latin-300.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'), url('../fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v20-latin-300.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-regular - latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v20-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'), url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-700 - latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v20-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v20-latin-700.svg#Roboto') format('svg');
    /* Legacy iOS */
}




body {
    position: relative;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: #000;
}





::-webkit-selection {
    color: #ffffff;
    background: #1896d4;
}

::-moz-selection {
    color: #ffffff;
    background: #1896d4;
}

::selection {
    color: #ffffff;
    background: #1896d4;
}

a {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: #90d7ea;
    border-bottom: 1px solid #ccc;
}

a:hover, a:focus, a:active {
    outline: none;
    color: #2e2e2e;
    text-decoration: none;
    border-bottom: 1px solid #90d7ea;
}

a, a:hover, a:active {
    color: #1896d4;
    /* text-decoration: none; */
    border-bottom: 0px solid;
}

/* p a {
    text-decoration: none;
} */

input {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/* Heading */

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, header nav .nav-item .nav-link {
    font-family: 'Giorgio Sans Web';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    color: #000000;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin: 0 0 20px 0;
    padding: 0;
}

h1, .h1 {
    font-size: 45px;
    line-height: 52px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

h2, .h2 {
    font-size: 40px;
    letter-spacing: 1px;
    text-transform: none;
}

h3, .h3 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0px;
}

h4, .h4 {
    font-size: 16px;
    line-height: 28px;
}

h5, .h5 {
    font-size: 14px;
    line-height: 24px;
}

h6, .h6 {
    font-size: 12px;
    line-height: 24px;
}

ul, ol {
    padding-left: 15px;
    line-height: 26px;
}

ul ul, ul ol, ol ul, ol ol {
    padding-left: 25px;
}

ul, ol, p {
    margin: 0 0 20px 0;
}


